(function ($) {
  Drupal.behaviors.gnavSearchV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $html = $('html');
      var $search = $('.js-gnav-util-trigger--search', context);
      var $content = $('.js-gnav-util__content--search', context);
      var $close = $('.js-gnav-util-close', context);
      var $trigger = $('#gnav_util_trigger_search', context);
      var $searchFormTrigger = $('.js-search-form__btn', context);
      var $thisInput = $();
      var $searchInput = $('input#search', context);

      $searchInput.on('keydown', function (e) {
        if (e.which === 13) {
          e.preventDefault();
          $searchFormTrigger.trigger('click');
        }
      });

      $searchInput.on('focus', function () {
        $(this).val('');
      });

      $search.on('click', function (e) {
        e.preventDefault;
        $html.toggleClass('active-utility-overlay', true);
        $html.toggleClass('active-gnav', false);
        if ($trigger.prop('checked') === false) {
          $trigger.prop('checked', true);
        }

        $content.find('.js-search-term').delay(2000).focus().select();
      });
      $close.on('click', function (e) {
        e.preventDefault;
        $html.removeClass('active-utility-overlay');
        $(document).trigger('check_reset_gnav_radio_buttom');
      });
    }
  };
})(jQuery);
